import React from "react"
import RootElementBase from "acciondigital-tiendatruper-theme/src/components/rootElementBase"

import infoSitio from "../utils/infoSitio"

export const RootElement = ({ children }) => (
  <RootElementBase sitio={infoSitio.netlifySiteId}>{children}</RootElementBase>
)

export default RootElement
